.confirmation-box {
    background-color: #185da9;
    width: 100%;
    padding: 12px;
    padding-bottom: 0px;
    border-radius: 8px;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
}

#confirmationImage {
    margin-left: auto;
    margin-right: auto;
}

.confirmationFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
    min-height: 100%;
    height: 100%;
}