/* body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
body{ min-height: 100vh; text-align: center; font-family: 'Asap', sans-serif;}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

* {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
}

html {}

/* @font-face {
  font-family: 'Roboto', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
} */

::-webkit-scrollbar {
  display: none;
}

ul,
li {
  list-style: none;
}

p {
  color: rgba(64, 64, 64, 1.00);
  font-size: 15px;
  line-height: 24px;
  font-weight: 100
}

.error {
  color: rgb(235, 81, 109);
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: -20px;
  height: 16px;
}

label{
  display: block;
 font-size: 15px; 
  margin-bottom: 5px;
  color: #888;
  font-weight: 100;
}
label small{  font-size: 13px; }


h3 {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgba(246, 110, 99, 1.00)
}

input[type="date"],
input[type="text"],
input[type="email"],
input[type="time"]{
  border: 1px solid rgba(217, 217, 217, 1);
  font-size: 15px;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  font-weight: 300;
  -webkit-appearance: none
}

::placeholder{
  color: rgba(180, 180, 180, 1);
}

.select {
  -webkit-appearance: none;
  font-size: 15px;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  font-weight: 300;
  border-radius: 0;
  height: 39px;
  background: #fff url(./assets/select.png) no-repeat right 10px center / 20px;
  cursor: pointer;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 5px;
}

option:disabled {
  color: #B4B4B4;
  padding-left: 10px;
}

input[type="file"] {
  visibility: hidden;
  width: 0px;
}

html,
body {
  margin: 0;
  height: 100%;
}

/* body {
  text-align: center;
  ont-family: 'Roboto', sans-serif;
  color: rgba(64, 64, 64, 1.00);
  overflow: scroll;
} */

.certificateForm{
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
}

.select:focus {
  outline: none;
}


#root {height:fit-content; min-height: 100%; padding:12px; display: flex; flex: 1; flex-direction: column; background-color:#F4F8FA;}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: block;
  }
  .react-datepicker-wrapper {
    width: 270px
  }
}